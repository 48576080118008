import React, { Component, useState, useEffect } from "react";
import ERC20 from "../contracts/erc20.json";
import { BigNumber } from "bignumber.js";
import { ethers } from "ethers";
import { useWeb3React } from "@web3-react/core";
import { injectedConnector } from "../web3/connectors";
import disperse from "../contracts/Disperse.json";
import { DISPERSEABI } from "../assets/disperse";
import {
  Form,
  Input,
  TextArea,
  Button,
  Container,
  Divider,
  Grid,
  Header,
  Icon,
  List,
  Menu,
  Segment,
  Sidebar,
  Visibility,
} from "semantic-ui-react";

const home_height = {
  padding: "1em 0em",
  minHeight: "700px",
  // position: 'absolute',
  // bottom: '100px',
  // top:'100px',
  // width: '100%'
};


const address = DISPERSEABI.HPBMainnet;

const flag = true;

const Native = () => {
  const [textValue, setTextValue] = useState("");
  const { chainId, account, activate, active } = useWeb3React();
  const [currency, setCurrency] = useState("");
  const ph =
    "0x314ab97b76e39d63c78d5c86c2daf8eaa306b182 3.141592\n" +
    "0x271bffabd0f79b8bd4d7a1c245b7ec5b576ea98a,2.7182\n" +
    "0x141ca95b6177615fb1417cf70e930e102bf8f584:1.41421";

  const handleChange = (e) => {
    setTextValue(e.target.value);
  };

  const handleClick = async () => {

    const UNIT = 1000000000000000000;

    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    let tempArray = textValue.valueOf().split(/[\s,;:\t\r\n]+/);

    let oddArray = tempArray.filter((v, i) => i % 2);
    let evenArray = tempArray.filter((v, i) => !(i % 2));

    let addArray = [];
    let amtArray = [];
    let total = 0.0;

    for (let i = 0; i < evenArray.length; ++i) {
      if (!isNaN(oddArray[i]) && parseFloat(oddArray[i]) > 0.0) {
        addArray.push(evenArray[i].trim());
        amtArray.push((oddArray[i] * UNIT).toString());
        total += parseFloat(oddArray[i]);
      }
    }
    const disperseContract = new ethers.Contract(
      address,
      disperse.abi,
      provider
    );
    const disperseSigned = disperseContract.connect(signer);
    let overrides = { value: ethers.utils.parseEther(total.toString()) };
    if (addArray.length === amtArray.length && addArray.length > 0) {
      let tx = await disperseSigned.disperseHPB(
        addArray,
        amtArray,
        overrides
      );
    } else {
      alert("Please enter at least one valid transaction");
    }
  };

  useEffect(() => {
    if (chainId === 269) {
      setCurrency("HPB");
    }
  }, [chainId]);

  if (chainId === 269 && active) {
    return (
      <Segment textAlign="left" vertical ={flag} inverted ={flag}>
        <Grid>
          <Grid.Row>
            <Grid.Column width={2}></Grid.Column>
            <Grid.Column width={12}>
              <Form>
                <Form.Field>
                  <Header as="h2" inverted  ={flag}>
                    <Header.Content>Disperse {currency}</Header.Content>
                  </Header>
                </Form.Field>
                <Form.Field>
                  <p style={{ fontSize: 18 }} inverted="true" >
                  Please enter each wallet address in a new line followed by a blank space, 
                  comma or colon and the amount (as a number) to be sent to that address.

                  </p>
                </Form.Field> 
                <Form.Field>
                  <TextArea placeholder={ph} onChange={handleChange} />
                </Form.Field>
                {/* <Divider /> */}
                <Form.Field>
                  <Button primary size="big" onClick={handleClick}>
                    Disperse
                  </Button>
                </Form.Field>
              </Form>
            </Grid.Column>
            <Grid.Column width={2}></Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    );
  } else {
    return <div></div>;
  }
};

const TokensNew = () => {
  const [textValue, setTextValue] = useState("");
  const [tokenAddress, setTokenAddress] = useState("");
  const [disableDisperse, setDisableDisperse] = useState(true);
  const [disableApprove, setDisableApprove] = useState(false);
  const [addressArray, setAddressArray] = useState([]);
  const [amountArray, setAmountArray] = useState([]);

  const { chainId, account, activate, active } = useWeb3React();
  const ph =
    "0x314ab97b76e39d63c78d5c86c2daf8eaa306b182 3.141592\n" +
    "0x271bffabd0f79b8bd4d7a1c245b7ec5b576ea98a 2.7182\n" +
    "0x141ca95b6177615fb1417cf70e930e102bf8f584 1.41421";

  const handleChange = (e) => {
    setTextValue(e.target.value);
  };

  const handleInput = (e) => {
    setTokenAddress(e.target.value);
  };

  const handleApprove = async () => {

    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    let tempArray = textValue.valueOf().split(/[\s,;:\t\r\n]+/);

    let oddArray = tempArray.filter((v, i) => i % 2);
    let evenArray = tempArray.filter((v, i) => !(i % 2));

    let addArray = [];
    let amtArray = [];
    // let total = 0.0;
    let totalBig = new BigNumber(0)

    const erc20Contract = new ethers.Contract(tokenAddress, ERC20, provider);
    const erc20Signed = erc20Contract.connect(signer); 

    //获取合约精度 ，使用 call 来执行 pure 或 view 智能合约方法。
    //这些方法不会修改区块链，也不会花费任何执行成本，也不会向网络广播。
    let decimals = await erc20Contract.decimals(); 

    const UNIT = 1 * 10 ** parseInt(decimals);  
    const minValue = BigNumber(1).div(UNIT).toString()

    let flag = true;
    let strErr ='';
    // debugger
    for (let i = 0; i < evenArray.length; ++i) {
      if (!isNaN(oddArray[i]) && parseFloat(oddArray[i]) > 0.0) {
        addArray.push(evenArray[i].trim());
        amtArray.push("0x" + (oddArray[i] * UNIT).toString(16));
        if(BigNumber(minValue).comparedTo(oddArray[i] ).toString() ==='1'){
          flag = false;
          strErr += evenArray[i].trim() +' input the value is error, the minValue is' +minValue + '. '; 
        }
        // total += parseFloat(oddArray[i]);
        totalBig = totalBig.plus(oddArray[i])
      }
    }

    if(!flag){
      alert(strErr);
      return ;
    }

    setAddressArray(addArray);
    setAmountArray(amtArray);
 

    let allowance = totalBig ;//new BigNumber(total);

    allowance = allowance.multipliedBy(UNIT);
    console.log(allowance.toString());
    console.log(tokenAddress);
    console.log(addArray);
    console.log(amtArray);

    const toHex = "0x" + allowance.toString(16);

    const success = await erc20Signed.approve(address, toHex);

    setDisableDisperse(false);
  };

  const handleDisperse = async () => {
    setDisableApprove(true);
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();

    const disperseContract = new ethers.Contract(
      address,
      disperse.abi,
      provider
    );
    const disperseSigned = disperseContract.connect(signer);

    if (addressArray.length === amountArray.length && addressArray.length > 0) {
      let tx = await disperseSigned.disperseToken(
        tokenAddress,
        addressArray,
        amountArray
      );
    } else {
      alert("Please enter at least one valid transaction");
    }

    setAddressArray([]);
    setAmountArray([]);
    setDisableApprove(false);
    setDisableDisperse(true);
  };

  if (chainId === 269 && active) {
    return (
      <Segment textAlign="left" vertical inverted  ={flag}>
        <Grid>
          <Grid.Row>
            <Grid.Column width={2}></Grid.Column>
            <Grid.Column width={12}>
              <Form>
                <Form.Field>
                  <Header inverted ={flag} as="h2">
                    <Header.Content>Disperse Tokens</Header.Content>
                  </Header>
                </Form.Field>
                <Form.Field>
                  <Input
                    placeholder="Enter HRC20 Contract Address"
                    onChange={handleInput}
                  />
                </Form.Field>
                <Divider />
                <Form.Field>
                  <p style={{ fontSize: 18 }}>
                  Please enter each wallet address in a new line followed by a blank space, 
                  comma or colon and the amount (as a number) to be sent to that address.  
                  </p>
                  <p style={{ fontSize: 18 }}>
                  Note the extra step when sending HRC20 tokens. In this case you must Approve the relevant HRC20 token contract
                  first, before you Disperse. Each step requires confirmation using MetaMask.
                  </p>
                </Form.Field>
                <Divider />
                <Form.Field>
                  <TextArea placeholder={ph} onChange={handleChange} />
                </Form.Field>
                <Divider />
                <Form.Field>
                  <Button
                    primary
                    size="big"
                    onClick={async () => handleApprove()}
                    disabled={disableApprove}
                  >
                    Approve
                  </Button>
                  <Button
                    primary
                    size="big"
                    onClick={async () => handleDisperse()}
                    disabled={disableDisperse}
                  >
                    Disperse
                  </Button>
                </Form.Field>
              </Form>
            </Grid.Column>
            <Grid.Column width={2}></Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    );
  } else {
    return <div></div>;
  }
};

class DesktopContainer extends Component {
  render() {
    return (
      <Container textAlign="center"  vertical="true"  inverted="true" >
        <Grid>
          <Grid.Row>
            {/* <Grid.Column width={1}></Grid.Column> */}
            <Grid.Column width={16}>
              <Header as="h2" inverted ={flag}  color='blue'  content=" distribute HPB or tokens to multiple addresses"
                style={{  fontSize: "3em",  fontWeight: "normal",  marginTop: "0.2em",marginBottom:'0.5em' }} /> 
            </Grid.Column>
            {/* <Grid.Column width={1}></Grid.Column> */}
          </Grid.Row>
        </Grid>
      </Container>
    );
  }
}

const HomeNew = () => {
  const { chainId, account, activate, active } = useWeb3React();

  if (active) {
    return (
      <Segment style={home_height} textAlign="center" inverted ={flag} vertical ={flag}>
        <DesktopContainer />
        <Grid>
          <Grid.Row>
            <Grid.Column width={16}>
              <Native />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column width={16}>
              <TokensNew />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    );
  } else {
    return (
      <Segment textAlign="left" style={home_height} inverted ={flag} vertical ={flag}>
        <DesktopContainer />
        <Grid style={{marginTop:50}}>
          <Grid.Row>
            <Grid.Column width={3}></Grid.Column>
            <Grid.Column width={10}>
              <Grid.Row>
                {!(chainId === 269) ? (
                  <Header as="h1" inverted >
                    This DAPP only supports HPB and HRC20 tokens found on HPB Main Network (Chain ID: 269)
                  </Header>
                ) : (
                  <div></div>
                )}
              </Grid.Row>
              <Grid.Row>
                {!active ? (
                  <Header as="h2" color="blue" style={{ marginTop: 20 }}>
                    Please Connect To MetaMask
                  </Header>
                ) : (
                  <div></div>
                )}
              </Grid.Row>
            </Grid.Column>
            <Grid.Column width={3}></Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    );
  }
};

export default HomeNew;
