import React from 'react'; 
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import { Web3ReactProvider } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';

import Toolbar from './components/Toolbar';
import Home from './containers/Home';
import HomeNew from './containers/HomeNew';
import Native from './containers/Native';
import NativeNew from './containers/NativeNew';
import Tokens from './containers/Tokens';
import TokensNew from './containers/TokensNew';
import HeaderUI from './components/Header';
import Footer from './components/Footer';

function getLibrary(provider, connector) {
  return new Web3Provider(provider) // this will vary according to whether you use e.g. ethers or web3.js
}

function App() {

  return (
    <Router>
      <Web3ReactProvider getLibrary={getLibrary}>
        {/* <Toolbar /> */}
        <HeaderUI />
          <Switch>
           {/*  <Route path="/Tokens">
              <TokensNew />
            </Route>
            <Route path="/Native">
              <NativeNew />
            </Route> */}
            <Route path="/">
              <HomeNew />
            </Route>
          </Switch>
          <Footer/>
      </Web3ReactProvider>
    </Router>
  );
}

export default App;