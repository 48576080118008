import React, { Component ,useState} from 'react'
import { useLocation } from 'react-router'
import {Link } from 'react-router-dom'; 
import {
  Button,
  Container, 
  Menu,
  Icon,
  Grid,  
  Divider,
  List,
  Segment,  
  Image, Item ,
} from 'semantic-ui-react'
import { useWeb3React } from '@web3-react/core';
import { injectedConnector } from '../web3/connectors';
import { Balance} from '../components/WalletConnector';


const footer_heigt = {
  height: '100px',
  padding: '1em 0em',
  // position: 'absolute',
  // bottom: '0',
  // top:'0',
  // width: '100%'
}

const HeaderUI = () => {

    const location = useLocation()
    const routerName = () => { 
        console.log(location.pathname)
        return location.pathname
      }

    const [activeItem,setActiveItem] = useState(routerName());   
    
    const menuClick = (e, { name }) => { 
        if(name ==='Disperse DAPP'){
            setActiveItem("/");  
        }  else if(name ==='Tokens'){
            setActiveItem("/Tokens");  
        } else if(name ==='Native'){
            setActiveItem("/Native");  
        }
      
    }

    const { chainId, account, activate, active } = useWeb3React();
    const onButtonClick = () => {
        activate(injectedConnector);
    } 
    const fixed = false; 
 

    return ( 
        <Segment     textAlign='center'  style={{ height: 100, padding: '1em 0em',border:'none' }}  vertical  inverted >  
            <Menu fixed={fixed ? 'top' : null}  inverted pointing={!fixed} secondary={!fixed} size='large' >  
              <Container style={{height:50,fontSize:18,width:'90%'}}>
                <Image src={'/hpbscan-logo.svg'} size='small' verticalAlign='middle' />{' '}  
                {/* <Menu.Item  name='Disperse DAPP'  />    */}
                {active ?'':    
                    <Menu.Item position='right' >
                        <Button as='a'  primary  onClick={onButtonClick}>
                                Connect To MetaMask
                        </Button> 
                    </Menu.Item>
                } 
                {active ?
                     (
                      <Menu.Menu position='right'  >  
                        <Menu.Item   >                              
                          <Button  primary  onClick={onButtonClick}>
                             <Icon name='envelope outline' />{account.substring(0,6)} ...{ account.substring(account.length - 6, account.length)}
                          </Button> 
                        </Menu.Item>
                         
                        <Menu.Item  > 
                            <Button   primary  onClick={onButtonClick}>
                            <Icon name='money bill alternate outline' />
                                <Balance />
                                </Button> 
                         </Menu.Item>
                      </Menu.Menu>  
                    ) :''    
                } 
         
              </Container>

         
            </Menu>  
            <Divider />
        </Segment>

      
    )
}

export default HeaderUI;